import Add from '@icons/add.svg'
import Delete from '@icons/delete.svg'
import DoNotDisturbOn from '@icons/do_not_disturb_on.svg'
import Edit from '@icons/edit.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useProjectId from 'src/components/hooks/useProjectId'
import { useDeliveryColumns } from 'src/components/process/delivery/useDeliveryColumns'
import { IMetaValue } from 'src/document/types/IMetaData'
import { deliveriesExportData } from 'src/export-templates/DeliveryExports'
import { deliveryImportTemplate } from 'src/export-templates/DeliveryImportTemplate'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import useUserAccess from 'src/hooks/useUserAccess'
import { useFilteredDeliveries } from 'src/query/planning/deliveries'
import {
  deleteBulkDeliveries,
  deleteDeliveryById,
  updateDelivery,
} from 'src/service/DeliveryService'
import {
  IDelivery,
  IImportItemList,
  IKeypoint,
  ISystemTypeGroup,
} from 'src/service/OrgTypes'
import { removeDeliveriesFromKeypoint } from 'src/service/ProcessService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import useAlert from 'src/ui-elements/toast/useAlert'
import { addUserDefinedInfoToImport } from 'src/utility/exportUtils'
import { userDefinedColumns } from '../../TableColumns/Columns'
import KeypointChangeLogModal from '../main-process/KeypointChangeLogModal'
import DeliveryForm from './DeliveryForm'
import DeliveryInspectorPanel from './DeliveryInspectorPanel'

interface IDeliveryTableProps {
  keypoint?: IKeypoint
  isExpandedElement?: boolean
  readOnly?: boolean
  tableName?: string
  improvementId?: number
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadParent?: () => void
  systemTypeGroup?: ISystemTypeGroup
  filter?: Omit<TableFilter, 'sort'>
  tableButtons?: JSX.Element[]
  inInspector?: boolean
}

const DeliveryTable = ({
  keypoint,
  isExpandedElement,
  readOnly,
  improvementId,
  systemTypeGroup,
  onOpenItem,
  reloadParent,
  tableName = 'projectDeliveriesList',
  filter,
  tableButtons,
  inInspector = false,
}: IDeliveryTableProps) => {
  const { writeAccess: writeAccessPlanning } = useUserAccess('planning')
  const writeAccess = keypoint ? !readOnly : !readOnly && writeAccessPlanning

  const { t } = useTranslation()
  const projectId = useProjectId()

  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery>(
    {} as IDelivery,
  )
  const [deliveryFormOpen, setDeliveryFormOpen] = useState<boolean>(false)
  const [selectedDeliveryIds, setSelectedDeliveryIds] = useState<number[]>()
  const [deliveryFormState, setDeliveryFormState] =
    useState<Partial<IDelivery>>()
  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')
  const { addAlert } = useAlert()

  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(0)

  const queryClient = useQueryClient()

  const reloadDeliveries = () => {
    queryClient.invalidateQueries({ queryKey: ['deliveries'] })
  }
  const reload = () => {
    reloadDeliveries()
    reloadParent?.()
  }

  const importDeliveryTemplate: IImportItemList = {
    title: t('upload_deliveries'),
    templateJson: addUserDefinedInfoToImport(
      deliveryImportTemplate,
      [],
      systemTypeGroup?.optional_fields ?? [],
    ),
    type: 'deliveries',
    reload,
  }

  const onRowClick = (row: IDelivery) => {
    if (onOpenItem) {
      onOpenItem(row.id, 'delivery')
    } else {
      setOpenInspection(true)
      setSelectedDelivery(row)
    }
  }

  const onCloneItem = (row: IDelivery) => {
    const delivery: Partial<IDelivery> = {
      ...row,
      name: `Copy of ${row.name}`,
      copy_from_id: row.id,
      id: undefined,
    }
    setDeliveryFormOpen(true)
    setDeliveryFormState(delivery)
  }

  const onDateChange = (delivery: IDelivery, date: string) => {
    setSelectedDelivery({ ...delivery, endTime: date })
    setChangeLogReason('')
    setChangeLogModal(true)
  }

  const onChangeLogModalClose = () => {
    setChangeLogModal(false)
    setSelectedDelivery({} as IDelivery)
  }

  const onChangeLogSubmit = async () => {
    setChangeLogModal((prevState) => !prevState)
    if (selectedDelivery.id) {
      await updateDelivery(
        { ...selectedDelivery, change_reason: changeLogReason },
        selectedDelivery.id,
      )
      setSelectedDelivery({} as IDelivery)
      reload()
    }
  }

  const { confirmDelete } = useDeleteModal()

  const deleteDelivery = async (row: IDelivery) => {
    if (keypoint) {
      await removeDeliveriesFromKeypoint(keypoint.id, projectId, [row.id])
      reload()
    } else {
      const confirm = await confirmDelete({
        itemIdnType: `${row.record_id} (${t('delivery')})`,
        itemName: `${row.record_id} - ${row.name}`,
      })
      if (!confirm) return
      await deleteDeliveryById(row.id)
      reload()
    }
  }

  const deleteSelectedDeliveries = async (deliveries: number[]) => {
    await deleteBulkDeliveries(projectId, deliveries)
    addAlert({
      type: 'success',
      title: t('successfully_deleted'),
      description: t('selected_deliveries_are_deleted'),
      autoClose: true,
    })
    reload()
  }

  const onUpdateProjectDeliveriesList = () => {
    reload()
  }

  const updateMetaField = useCallback((data: IMetaValue) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload()
      })
    }
  }, [])

  const userDefinedAttributesColumns = useMemo(() => {
    if (systemTypeGroup) {
      return userDefinedColumns(
        systemTypeGroup.optional_fields,
        updateMetaField,
        'meta_data',
      )
    } else {
      return []
    }
  }, [systemTypeGroup, updateMetaField])

  const handlePreviewClick = (data: IDelivery) => {
    setOpenInspection(true)
    if (data.id) {
      setSelectedDeliveryId(data.id)
    }
  }

  const customFilter = useMemo(
    () => ({
      ...(keypoint && { key_point: [keypoint.id] }),
      ...(improvementId && { improvement: [improvementId] }),
      ...filter,
    }),
    [improvementId, keypoint, filter],
  )

  const { columns, defaultOrdering, inspectorPanelColumnVisibility } =
    useDeliveryColumns({
      disabled: !writeAccess,
      onDateChange: onDateChange,
      reload: reload,
    })

  return (
    <div className={'w-full flex flex-col'}>
      <Table
        name={tableName}
        modelName={DataModel.DELIVERY}
        useDataQuery={useFilteredDeliveries}
        initialFilter={customFilter}
        legacyColumns={userDefinedAttributesColumns}
        onPreviewClick={handlePreviewClick}
        onDeleteClick={writeAccess ? deleteDelivery : undefined}
        onCopyClick={writeAccess ? onCloneItem : undefined}
        onRowClick={onRowClick}
        deleteIcon={keypoint ? DoNotDisturbOn : Delete}
        tableButtons={
          !isExpandedElement
            ? (selectedItems) => ({
                exportData: deliveriesExportData,
                onBulkDelete:
                  writeAccess && !keypoint
                    ? deleteSelectedDeliveries
                    : undefined,
                importItem: writeAccess ? importDeliveryTemplate : undefined,
                customButtons: writeAccess
                  ? [
                      <Button
                        key="new_delivery"
                        onClick={() => setDeliveryFormOpen(true)}
                        type={ButtonType.PRIMARY}
                      >
                        <Add className={'fill-white text-xl'} />
                        {t('new_delivery')}
                      </Button>,
                      ...(keypoint
                        ? [
                            <Button
                              key="remove_deliveries"
                              className={'h-8 w-8 bg-red-100 p-0'}
                              disabled={!selectedItems.length}
                              onClick={async () => {
                                await removeDeliveriesFromKeypoint(
                                  keypoint.id,
                                  projectId,
                                  selectedItems.map((delivery) => delivery.id),
                                )
                                reload()
                              }}
                              type={ButtonType.PRIMARY}
                            >
                              <DoNotDisturbOn
                                className={twMerge(
                                  'fill-red-danger text-xl',
                                  !selectedItems.length && 'fill-dark-grey',
                                )}
                              />
                            </Button>,
                          ]
                        : []),
                      <Button
                        key="mass_edit"
                        className={'h-8 w-8 p-0'}
                        disabled={!selectedItems.length}
                        onClick={() => {
                          setDeliveryFormOpen(true)
                          if (selectedItems.length === 1) {
                            setDeliveryFormState(selectedItems[0])
                          } else {
                            setSelectedDeliveryIds(
                              selectedItems.map((delivery) => delivery.id),
                            )
                          }
                        }}
                        type={ButtonType.SECONDARY}
                      >
                        <Edit
                          className={twMerge(
                            'fill-blue text-xl',
                            !selectedItems.length && 'fill-dark-grey',
                          )}
                        />
                      </Button>,
                      ...(tableButtons ?? []),
                    ]
                  : undefined,
              })
            : undefined
        }
        columns={columns}
        defaultOrdering={defaultOrdering}
        initialColumnVisibility={
          inInspector ? inspectorPanelColumnVisibility : undefined
        }
      />

      {deliveryFormOpen ? (
        <DeliveryForm
          show={true}
          reload={onUpdateProjectDeliveriesList}
          delivery={deliveryFormState ?? { key_point_id: keypoint?.id }}
          deliveryIds={selectedDeliveryIds}
          closeModal={() => {
            setDeliveryFormOpen(false)
            setDeliveryFormState(undefined)
            setSelectedDeliveryIds(undefined)
          }}
        />
      ) : null}

      {changeLogModal && selectedDelivery.id ? (
        <KeypointChangeLogModal
          show={changeLogModal}
          toggleChangeLogModal={onChangeLogModalClose}
          onChangeLogSubmit={onChangeLogSubmit}
          onChangeReasonLog={setChangeLogReason}
        />
      ) : null}

      {openInspectorPanel && selectedDeliveryId ? (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          open={openInspectorPanel}
          onClose={() => setOpenInspection(false)}
          projectId={projectId}
          onOpenItem={onOpenItem}
          onUpdate={reload}
        />
      ) : undefined}
    </div>
  )
}

export default DeliveryTable
